import React, { createContext, useCallback, useEffect, useRef, useState } from "react";

import "./App.css";

import { PwaContextProvider } from "./context/PwaContext";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { Route, Routes, useNavigate } from "react-router-dom";

// Import Material
import { CssBaseline, useMediaQuery } from "@mui/material";

//Import Styles
import { createTheme, ThemeProvider, } from "@mui/material/styles";

// import Page
// import TemporaryLandingPage from "./Pages/TemporaryLandingPage";
import DeliveryPage from "./Pages/DeliveryPage/DeliveryPage";
import PageNotFound from "./Pages/PageNotFound";
import Verify from "./Pages/Verify";

//import Components
// import AppBarResponsive from "./Components/AppBarResponsive/AppBarResponsive";
import TrackingPage from "./Pages/TrackingPage/TrackingPage";

// import Constans
import { ColorTheme } from "./Constants/ColorTheme";
import { UrlPage } from "./Constants/UrlPage";

// import Dialog
import EmailNotificationDialog from "./Components/DialogComponent/EmailNotificationDialog";
import EmailNotificationSaveDialog from "./Components/DialogComponent/EmailNotificationSaveDialog";
import EmailsListDialog from "./Components/DialogComponent/EmailsListDialog";
import LoadingDialog from "./Components/DialogComponent/LoadingDialog";
import LoginDialog from "./Components/DialogComponent/LoginDialog";
import LogoutConfirmationDialog from "./Components/DialogComponent/LogoutConfirmationDialog";
import EmailActivation from "./Components/DialogComponent/OtpDialog/EmailActivation";
import OtpDialog from "./Components/DialogComponent/OtpDialog/OtpDialog";
import RolesAccessDialog from "./Components/DialogComponent/RolesAccessDialog";
import SettingDisabledDialog from "./Components/DialogComponent/SettingDisabledDialog";
import WelcomeDialog from "./Components/DialogComponent/WelcomeDialog";

import demoLogo from "../src/assets/images/demo_logo.svg";

// Import Dump data
import { debounce } from "lodash";
import { useCookies } from "react-cookie";
import { CORE_ACTION_GET_ADMIN_CONFIGURATION, CORE_ACTION_GET_CONNECTED_OUTLETS_BY_OUTLET_ID, CORE_ACTION_GET_OUTLET_AND_MAPPED_OUTLET_EMAILS_BY_OUTLETID } from "./Actions/CoreActions";
import InviteUserDialog from "./Components/DialogComponent/InviteUserDialog";
import NavigationDrawer from "./Components/NavigationDrawer/NavigationDrawer";
import { DialogType } from "./Constants/DialogType";
import { ETABehaviourConfigSettingStatus } from "./Constants/ETABehaviourConfigSettingStatus";
import { RoleEnum } from "./Constants/RoleEnum";
import { TimerResendActivation } from "./Constants/TimerResendActivation";
import { promoDummyData } from "./dump-data";
import ConfigurationPage from "./Pages/ConfigurationPage/ConfigurationPage";
import DeeplinkRedirect from "./Pages/DeeplinkRedirect";
import SignIn from "./Pages/SignIn/SignIn";
import SignUp from "./Pages/SignUp/SignUp";
import { ACTIVE_STATIC_URL } from "./utils/environment";
import { usePersistentState } from "./utils/UsePersistentState";
import { getAppTheme } from "./utils/util";

// AppContext
export const AppContext = createContext({});

const Main = (props) => {
  const { openDialog, isError } = props

  return (
    <>
      {/* ======== Global Dialog Component ======== */}
      {props.isLoadingLogin && (<LoadingDialog />)}

      <LoginDialog isOpen={openDialog === 'login'} />
      <LogoutConfirmationDialog isOpen={openDialog === 'logout'} />
      <OtpDialog isOpen={openDialog === 'otp'} />
      <SettingDisabledDialog isOpen={openDialog === 'settingDisabled'} />
      <WelcomeDialog isOpen={openDialog === 'welcomeGreeting'} />
      <EmailActivation isOpen={openDialog === 'emailActivation'} />

      <EmailNotificationDialog isOpen={openDialog === 'emailNotification'} />
      <EmailNotificationSaveDialog isOpen={openDialog === 'emailNotificationSave'} />
      <EmailsListDialog isOpen={openDialog === 'emailList'} />
      <RolesAccessDialog isOpen={openDialog === 'rolesAccess'} />
      {/* ======== Global Dialog Component ======== */}

      {isError ? (
        <PageNotFound />
      ) : (
        <Routes>
          <Route path={UrlPage.VERIFY} element={<Verify />} />
          <Route path={UrlPage.DELIVERY_NO_LINK} element={<DeliveryPage />} />
          <Route path={UrlPage.DELIVERY} element={<DeliveryPage />} />
          <Route path={UrlPage.CONFIGURATION} element={<ConfigurationPage />} />
          <Route path={UrlPage.TRACKINGPAGE} element={<TrackingPage />} />
          <Route path={UrlPage.SIGN_UP} element={<SignUp />} />
          <Route path={UrlPage.SIGN_IN} element={<SignIn />} />
          {/* <Route path={`${UrlPage.SIGN_UP}/:registrationCode`} element={<SignUp />} /> */}
          <Route exact path={UrlPage.DEMO} element={<DeliveryPage />} />
          <Route exact path={UrlPage.REDIRECT} element={<DeeplinkRedirect />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </>
  );
};


function App({ callbackPWA }) {
  /** Cleanup old states */
  const [cookies, setCookie, removeCookie] = useCookies(['initialUrl']);

  useEffect(() => {
    localStorage.removeItem('lastVisitedUrl')
    localStorage.removeItem('install_url')
    localStorage.removeItem('use-persistent-state-link')
    removeCookie('initialUrl')
  }, [])

  const navigate = useNavigate();

  const [hasRegisterSelectedOutlets, setHasRegisterSelectedOutlets] = useState(false)

  /* ==================== Change Theme ==================== */
  const [mode, setMode] = useState(ColorTheme.LIGHT)
  const [customerName, setCustomerName] = useState("")
  const [logo, setLogo] = useState('')
  const [isLogoImage, setIsLogoImage] = useState(false)
  const [showAppBar, setShowAppBar] = useState(true)
  const [shouldShowSettings, setShouldShowSettings] = useState(true)
  const [settingDisabled, setSettingDisabled] = useState(false)

  const [isError, setIsError] = useState(false)

  const [rolesOption, setRolesOption] = useState([])

  /* ================== Tenant Info ===================== */
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [signUpImage, setSignUpImage] = useState("")

  /* ================== Tenant Config =================== */
  const [isMarkEmptyItemAsNotDeliverable, setIsMarkEmptyItemAsNotDeliverable] = useState(true)

  /* ================== Persisted data ===================== */
  const [outletId, setOutletId] = usePersistentState('outletId', null);
  const [loggedinEmail, setLoggedinEmail] = usePersistentState('loggedinEmail', null);
  const [persistedLinkString, setPersistedLinkString] = usePersistentState('persistedLinkString', null)
  /* ================== Data ===================== */
  const [noSuperAdmin, setNoSuperAdmin] = useState(false)
  const [promoNewsData, setPromoNewsData] = useState([])
  const [alertInfo, setAlertInfo] = useState({
    alertMessage: '',
    notifyAlert: false
  })
  const [currentEmail, setCurrentEmail] = useState({
    id: null,
    email: "",
    role: RoleEnum.SUPER_ADMIN,
  });

  /* ========= Email for State Management add,edit,delete Admin Account ============ */
  // This Email list only used for Role Admin View, for SuperAdmin use emailList from outletList
  const [emailList, setEmailList] = useState([]);
  const [initStateEmailList, setInitStateEmailList] = useState(null)
  // selected email for edit or delete
  const [selectedEmail, setSelectedEmail] = useState(null);
  /* ========= EOL Email for State Management Admin Account ============ */

  const [dataToSaveConfigurationPage, setDataToSaveConfigurationPage] = useState([])
  const [showConfirmationSnackbar, setShowConfirmationSnackbar] = useState(false)
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false)

  /* ========= Email for State Management add,edit,delete SuperAdmin Account ============ */
  // selected outlet for edit or delete
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [outletList, setOutletList] = useState([])
  const [initOutletList, setInitOutletList] = useState([])
  /* ========= EOL Email for State Management SuperAdmin Account ============ */

  /* ========= Pagination OutletList ============ */
  // const [querySearch, setQuerySearch] = useState("")
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  /* ========= EOL Pagination OutletList ============ */

  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [listOfOutlets, setListOfOutlets] = useState([])
  const [isDisplayApiSuccesMessage, setIsDisplayApiSuccesMessage] = useState(false)
  /* =====================EOL Data ===================== */

  /* ======== ETA Email Automation Configuration ======== */
  const [etaBehaviorConfig, setEtaBehaviorConfig] = useState(null)
  const [etaUserConfig, setEtaUserConfig] = useState(null)
  const [defaultEtaUserConfig, setDefaultEtaUserConfig] = useState(null)
  const [isHasGetOutletWithConfig, setIsHasGetOutletWithConfig] = useState(null)
  const [showEtaSettingList, setShowEtaSettingList] = useState({
    isDeliveryConfirmed: true,
    isDeliveryCanceled: true,
    isDeliveryStarted: true,
    isEtaAnnounced: true,
    isEtaUpdated: true,
    isEtaChanged: true
  })
  // const [automationConfigPayload, setAutomationConfigPayload] = useState([])
  /* ======== EOL ETA Email Automation Configuration ======= */

  /* ================== Link Status ===================== */
  const [isLinkExpired, setIsLinkExpired] = useState(true);
  /* ================== EOL Link Status ===================== */

  const [isEmailAutomationDialogOpen, setIsEmailAutomationDialogOpen] = useState(false)
  /* =========Breakpoint device============ */
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isBigPhone = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  /* =========EOL Breakpoint device============ */

  /** ======== State for navigation drawer ======== */
  const [anchorNavigationDrawerEl, setAnchorNavigationDrawerEl] = useState(null);
  const isNavigationDrawerOpen = Boolean(anchorNavigationDrawerEl)

  const handleOpenNavigationDrawer = event => {
    setAnchorNavigationDrawerEl(event.currentTarget);
  }

  const handleCloseNavigationDrawer = () => {
    setAnchorNavigationDrawerEl(null);
  };

  /** ======== EOL state for navigation drawer ======== */

  /** ===============Dialog =============== */
  const [openDialog, setOpenDialog] = useState('');

  const handleOpenDialog = (dialogName, force = false) => {
    setAnchorNavigationDrawerEl(null);

    if (dialogName === 'welcomeGreeting' && force === false) {
      if (!localStorage.getItem("welcomeGreeting")) {
        localStorage.setItem("welcomeGreeting", true);
        setOpenDialog(dialogName);
      }
    }
    else {
      setOpenDialog(dialogName);
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog('');
  }

  /** ===============EOL Dialog =============== */

  /** ==========================OTP Dialog ========================== */
  const [sendOtp, setSendOtp] = useState(false);

  const handleButtonOtpDialog = () => {
    handleOpenDialog('emailActivation');
    setSendOtp(true);
  };

  const handleCloseOtpDialog = () => {
    handleCloseDialog();
    setSendOtp(false);
  };
  /** ==========================EOL OTP Dialog ========================== */

  /** ==========================Email Activation ========================== */
  // Email activation countdown
  const [resendTimer, setResendTimer] = useState(TimerResendActivation.RESEND_TIMER)
  const [expiredTimer, setExpiredTimer] = useState(TimerResendActivation.EXPIRED_TIMER)
  const [currentPage, setCurrentPage] = useState("")
  /** ==========================EOL Email Activation ========================== */


  /** ========================== Authentication and Authorization ========================== */
  const [isLogin, setIsLogin] = useState(false);

  const [isLoadingLogin, setIsLoadingLogin] = useState(false)

  const [isLoadingTenant, setIsLoadingTenant] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [isApiLoading, setIsApiLoading] = useState(false)

  const [userRole, setUserRole] = useState(RoleEnum.SUPER_ADMIN);

  const handleLogin = () => {
    setIsLogin(true);
  };
  /** ========================== EOL Authentication and Authorization ========================== */

  /** ===============Logout =============== */

  const handleLogout = () => {
    handleCloseDialog();
    setIsLogin(false);
    setLoggedinEmail(null);
    setOutletId(null);
    setPersistedLinkString(null)
    setNoSuperAdmin(true)
    setIsLoading(false)

    navigate(`/${persistedLinkString}`, { replace: true })
  };
  /** ===============EOL Logout =============== */

  /* ================== Handle Scrolling ===================== */
  // handle scroll page delivery section
  const [scrollDown, setScrollDown] = useState(false);

  const toggleVisible = () => {
    if (document.getElementById("upcoming-deliveries-group")) {
      if (
        document.documentElement.scrollTop >=
        document.getElementById("upcoming-deliveries-group").clientHeight + 116
      ) {
        setScrollDown(true);
      } else {
        setScrollDown(false);
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);

  // handle scroll page to promo section
  const [isScrollToPromo, setIsScrollToPromo] = useState(false);

  const scrollToPromo = () => {
    // if (!isLinkExpired) {
    if (document.getElementById("deliverSection")) {
      if (
        document.documentElement.scrollTop >=
        document.getElementById("deliverSection").clientHeight - 110
      ) {
        setIsScrollToPromo(true);
      } else {
        setIsScrollToPromo(false);
      }
    }
  };
  window.addEventListener("scroll", scrollToPromo);

  const goToDelivery = () => {
    const element = document.getElementById("upcoming-deliveries-group")
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY - 120

      window.scrollTo({
        top: elementPosition,
        behavior: "smooth"
      })
    }
  }

  const goToPromo = () => {
    // document.getElementById("promo").scrollIntoView({ behavior: "smooth" });
    const element = document.getElementById("promo")
    if (element) {
      const elementPosition = element.getBoundingClientRect().bottom + window.scrollY - 152

      window.scrollTo({
        top: elementPosition,
        behavior: "smooth"
      })
    }
  };


  const tableContainerRef = useRef(null);

  const handleDetectOnScrollEnd = () => {
    const container = tableContainerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight) {
        let newPage = 1 + page
        setPage(newPage)
      }
    }
  }
  /* ================== EOL Handle Scrolling ===================== */

  const handleChangeTheme = (theme) => {
    // localStorage.setItem("mode", theme);
    setMode(theme)
  }

  const getTenantInfoData = async () => {
    if (window.location.pathname === ACTIVE_STATIC_URL) {
      // For demo system only
      setMode(ColorTheme.LIGHT)
      setPromoNewsData(promoDummyData)
      setLogo(demoLogo)
      setIsLogoImage(true)
    }
    else {
      setIsLoadingTenant(true)
      setIsLoadingLogin(true)
      CORE_ACTION_GET_ADMIN_CONFIGURATION(
        res => {
          let tenantInfo = res.data

          let tenantInfoGeneral = tenantInfo?.etaPortalTenantInfoGeneral
          let tenantInfoAnnouncement = tenantInfo?.etaPortalTenantInfoAnnouncement
          let tenantInfoConfiguration = tenantInfo?.etaPortalTenantInfoConfiguration

          if (tenantInfoGeneral) {
            if (tenantInfoGeneral?.logo.substring(0, 10) === 'data:image') {
              setIsLogoImage(true)
            }

            handleChangeTheme(tenantInfoGeneral?.colorTheme)
            setLogo(tenantInfoGeneral?.logo)
            setPhone(tenantInfoGeneral?.salesPhone)
            setEmail(tenantInfoGeneral?.salesEmail)
            setSignUpImage(tenantInfoGeneral?.signupImage)

            // if (general.favicon) {
            //   setFavicon(general.favicon)
            // }
          }

          if (tenantInfoAnnouncement) {
            const announcements = tenantInfoAnnouncement?.newsAndPromo
            const announcementAlert = {
              alertMessage: tenantInfoAnnouncement?.alertMessage || alertInfo.alertMessage,
              notifyAlert: tenantInfoAnnouncement?.notifyAlert || alertInfo.notifyAlert
            }
            setPromoNewsData(announcements)
            setAlertInfo(announcementAlert)
          }

          if (tenantInfoConfiguration) {
            setIsMarkEmptyItemAsNotDeliverable(tenantInfoConfiguration?.isMarkEmptyItemAsNotDeliverable)
            setEtaBehaviorConfig(tenantInfoConfiguration?.etaBehaviorConfig)
            setDefaultEtaUserConfig(tenantInfoConfiguration?.defaultUserConfigPortal)
          }

          setIsLoadingTenant(false)
          setIsLoadingLogin(false)
        },
        err => {
          console.error(err)
        })
    }
  }

  const handleCheckForMandatoryEtaAutomationSetting = (userConfig, type) => {
    let disabledKey = type + "Disabled"
    switch (type) {
      case "isDeliveryConfirmed":
        if (showEtaSettingList[disabledKey]) {
          return true
        } else {
          return userConfig.deliveryConfirmed
        }
      case "isDeliveryStarted":
        if (showEtaSettingList[disabledKey]) {
          return true
        } else {
          return userConfig.deliveryStarted
        }
      case "isDeliveryCanceled":
        if (showEtaSettingList[disabledKey]) {
          return true
        } else {
          return userConfig.deliveryCanceled
        }
      case "isEtaAnnounced":
        if (showEtaSettingList[disabledKey]) {
          return true
        } else {
          return userConfig.etaAnnounced
        }
      case "isEtaUpdated":
        if (showEtaSettingList[disabledKey]) {
          return true
        } else {
          return userConfig.etaUpdated
        }
      case "isEtaChanged":
        if (showEtaSettingList[disabledKey]) {
          return true
        } else {
          return userConfig.etaChanged
        }
      default:
        break;
    }
  }

  const getOutletWithMappedOutletEmails = async (outletId, email) => {
    if (window.location.pathname === ACTIVE_STATIC_URL) {
      setEtaUserConfig(null)
    }
    else {
      setIsLoading(true)
      CORE_ACTION_GET_OUTLET_AND_MAPPED_OUTLET_EMAILS_BY_OUTLETID(
        outletId,
        res => {
          if (res.data.outletEmailsWithUserConfig && res.data.outletEmailsWithUserConfig.length >= 1) {
            const mappedOutletEmails = res.data.outletEmailsWithUserConfig
            let registrationEmail = null
            registrationEmail = mappedOutletEmails.find(mappedEmail => mappedEmail.email === email)
            const userConfigFromEmail = registrationEmail?.userConfig

            if (registrationEmail !== null && typeof registrationEmail !== 'undefined') {
              setEtaUserConfig({
                isDeliveryConfirmed: handleCheckForMandatoryEtaAutomationSetting(userConfigFromEmail, "isDeliveryConfirmed"),
                isDeliveryStarted: handleCheckForMandatoryEtaAutomationSetting(userConfigFromEmail, "isDeliveryStarted"),
                isDeliveryCanceled: handleCheckForMandatoryEtaAutomationSetting(userConfigFromEmail, "isDeliveryCanceled"),
                isEtaAnnounced: handleCheckForMandatoryEtaAutomationSetting(userConfigFromEmail, "isEtaAnnounced"),
                isEtaUpdated: handleCheckForMandatoryEtaAutomationSetting(userConfigFromEmail, "isEtaUpdated"),
                isEtaChanged: handleCheckForMandatoryEtaAutomationSetting(userConfigFromEmail, "isEtaChanged")
              })
            }
            setIsHasGetOutletWithConfig(true)
            setIsLoading(false)
            setIsEmailAutomationDialogOpen(true)
          }
          else {
            setIsHasGetOutletWithConfig(true)
            setIsLoading(false)
            setIsEmailAutomationDialogOpen(true)
          }
        },
        err => {
          console.log(err)
          setIsLoading(false)
          setIsHasGetOutletWithConfig(false)
        }
      )
    }
  }

  useEffect(() => {
    if (loggedinEmail && loggedinEmail != "" && outletId && outletId != "") {
      handleLogin()
    }
  }, [loggedinEmail, outletId])

  // const handleMarkETAAsReadOnPageLoad = async () => {
  //   const queryString = window.location.pathname.replace("/", "")
  //   CORE_ACTION_GET_ETA_DATA(
  //     queryString,
  //     res => {
  //       if (res.status === STATUS_CODES.SUCCESS.OK) {
  //         const body = {
  //           parameter: queryString,
  //           etaPwaUrl: ACTIVE_ETA_PWA_URL,
  //           stopIds: []
  //         }

  //         // setLoggedinEmail(res.data.email)
  //         body.stopIds.push(res.data.stopId)
  //         // setOutletId(res.data.tourStops[0].outlet.id)

  //         CORE_ACTION_POST_MARK_ETA_AS_READ(
  //           body,
  //           res => {
  //             console.log(res)
  //           },
  //           err => {
  //             console.error(err)
  //           }
  //         )
  //       }
  //     },
  //     err => {
  //       // console.error(err)
  //     }
  //   )
  // }

  useEffect(() => {
    getTenantInfoData()
    // getOutletDatas()

    /**
     * Could be not useful anymore since there is no user role anymore (?)
     */
    // CORE_ACTION_GET_OUTLET_EMAILS_BY_OUTLET_ID_AND_ROLE(
    //   outletId,
    //   'SUPER_ADMIN',
    //   res => {
    //     if (res.data) {
    //       // console.log(res.data)
    //       if (res.data.outlet_emails.length === 0) {
    //         setNoSuperAdmin(true)
    //       }
    //     }
    //   },
    //   err => {
    //     // console.log(err)
    //     setNoSuperAdmin(false)
    //   })
  }, [])

  useEffect(() => {
    let clonedShowEtaSettingList = showEtaSettingList

    // Delivery confirmed
    clonedShowEtaSettingList.isDeliveryConfirmed = (etaBehaviorConfig?.deliveryConfirmed || etaUserConfig?.deliveryConfirmed) ?? false;

    if ((etaBehaviorConfig?.deliveryConfirmedSetting === ETABehaviourConfigSettingStatus.MANDATORY)) {
      clonedShowEtaSettingList.isDeliveryConfirmedDisabled = true
    }

    // Delivery started
    if ((etaBehaviorConfig?.deliveryStarted) || etaUserConfig?.deliveryStarted) {
      clonedShowEtaSettingList.isDeliveryStarted = true
    } else {
      clonedShowEtaSettingList.isDeliveryStarted = false
    }

    if ((etaBehaviorConfig?.deliveryStartedSetting === ETABehaviourConfigSettingStatus.MANDATORY)) {
      clonedShowEtaSettingList.isDeliveryStartedDisabled = true
    }

    // Delivery canceled
    clonedShowEtaSettingList.isDeliveryCanceled = (etaBehaviorConfig?.deliveryCanceled || etaUserConfig?.deliveryCanceled) ?? false;

    if ((etaBehaviorConfig?.deliveryCanceledSetting === ETABehaviourConfigSettingStatus.MANDATORY)) {
      clonedShowEtaSettingList.isDeliveryCanceledDisabled = true
    }

    // ETA announced
    if (etaBehaviorConfig?.firstRangeIsOneOrTwo || etaBehaviorConfig?.firstRangeIsComingSoon || etaUserConfig?.isEtaAnnounced) {
      clonedShowEtaSettingList.isEtaAnnounced = true
    } else {
      clonedShowEtaSettingList.isEtaAnnounced = false
    }

    if (etaBehaviorConfig?.firstRangeIsOneOrTwoSetting === ETABehaviourConfigSettingStatus.MANDATORY || etaBehaviorConfig?.firstRangeIsComingSoonSetting === ETABehaviourConfigSettingStatus.MANDATORY) {
      clonedShowEtaSettingList.isEtaAnnouncedDisabled = true;
    }

    // ETA updated
    if (etaBehaviorConfig?.newRangeIsOne || etaBehaviorConfig?.newRangeIsComingSoon || etaUserConfig?.isEtaUpdated) {
      clonedShowEtaSettingList.isEtaUpdated = true
    } else {
      clonedShowEtaSettingList.isEtaUpdated = false
    }

    if (etaBehaviorConfig?.newRangeIsOneSetting === ETABehaviourConfigSettingStatus.MANDATORY || etaBehaviorConfig?.newRangeIsComingSoonSetting === ETABehaviourConfigSettingStatus.MANDATORY) {
      clonedShowEtaSettingList.isEtaUpdatedDisabled = true
    }

    // ETA changed
    if (etaBehaviorConfig?.earlyThanPrevRangeOneOrTwo || etaBehaviorConfig?.earlyThanPrevRangeComingSoon || etaBehaviorConfig?.laterThanPrevRange || etaUserConfig?.isEtaChanged) {
      clonedShowEtaSettingList.isEtaChanged = true
    } else {
      clonedShowEtaSettingList.isEtaChanged = false
    }

    if (etaBehaviorConfig?.earlyThanPrevRangeOneOrTwoSetting === ETABehaviourConfigSettingStatus.MANDATORY || etaBehaviorConfig?.earlyThanPrevRangeComingSoonSetting === ETABehaviourConfigSettingStatus.MANDATORY || etaBehaviorConfig?.laterThanPrevRangeSetting === ETABehaviourConfigSettingStatus.MANDATORY) {
      clonedShowEtaSettingList.isEtaChangedDisabled = true
    }

    setShowEtaSettingList(clonedShowEtaSettingList)
  }, [etaBehaviorConfig, etaUserConfig])

  const handleSearchOutlet = useCallback(
    debounce((query) => {
      if (outletId) {
        CORE_ACTION_GET_CONNECTED_OUTLETS_BY_OUTLET_ID(
          outletId, (page), pageSize, { loggedInEmail: loggedinEmail, searchString: query },
          res => {
            let clonedResData = res.data
            if (clonedResData.length > 0) {

              if (dataToSaveConfigurationPage.length > 0) {
                dataToSaveConfigurationPage.forEach((eachDataToSave) => {
                  let selectedOutlet = clonedResData.find((data) => data.id === eachDataToSave.outletId)
                  let isEmailExist = selectedOutlet.outlet_emails.some((eachEmail) => eachEmail.email === eachDataToSave.email);

                  if (eachDataToSave.loggedInEmail !== null && eachDataToSave.loggedInEmail !== undefined) {
                    if (isEmailExist) {
                      selectedOutlet.outlet_emails = selectedOutlet.outlet_emails.filter((eachData) => eachData.email !== eachDataToSave.email)
                    }
                  }

                  if (!isEmailExist) {
                    selectedOutlet.outlet_emails.push(eachDataToSave)
                  }

                })
              }

              setOutletList(clonedResData);
              if (initOutletList.length <= 0) {
                setInitOutletList(clonedResData)
              }
            } else {
              setOutletList([])
            }

            // setIsLoading(false)
          },
          err => {
            // console.log(err)
            // setIsError(true)
            setIsLoading(false)
          })
      }
    }, 800),
    [hasRegisterSelectedOutlets, dataToSaveConfigurationPage, outletId] // Ensure the debounce function is only created once
  );

  useEffect(() => {
    handleSearchOutlet()
  }, [handleSearchOutlet, outletId])

  const AppContextValue = {
    mode,
    handleChangeTheme,
    logo,
    isLogoImage,
    email,
    phone,
    customerName,
    signUpImage,
    setCustomerName,

    noSuperAdmin,
    setNoSuperAdmin,

    isMobile,
    isTablet,
    isDesktop,
    isBigPhone,
    promoNewsData,

    emailList,
    setEmailList,

    isLinkExpired,
    alertInfo,

    scrollDown,
    scrollToTop,

    isScrollToPromo,
    goToPromo,
    goToDelivery,

    isLoadingLogin,
    setIsLoadingLogin,

    isLoadingTenant,
    setIsLoadingTenant,

    isLoading,
    setIsLoading,

    anchorNavigationDrawerEl,
    setAnchorNavigationDrawerEl,
    isNavigationDrawerOpen,
    handleOpenNavigationDrawer,
    handleCloseNavigationDrawer,

    setOpenDialog,
    openDialog,
    handleOpenDialog,
    handleCloseDialog,

    sendOtp,
    handleButtonOtpDialog,
    handleCloseOtpDialog,

    currentEmail,
    setCurrentEmail,

    isLogin,
    setIsLogin,

    userRole,
    setUserRole,
    handleLogin,
    handleLogout,

    showAppBar,
    setShowAppBar,

    shouldShowSettings,
    setShouldShowSettings,
    settingDisabled,
    setSettingDisabled,

    outletId,
    setOutletId,

    isError,
    setIsError,

    loggedinEmail,
    setLoggedinEmail,

    selectedEmail,
    setSelectedEmail,

    isMarkEmptyItemAsNotDeliverable,
    setRolesOption,
    rolesOption,
    currentPage,
    setCurrentPage,
    expiredTimer,
    setExpiredTimer,
    resendTimer,
    setResendTimer,

    setInitStateEmailList,
    initStateEmailList,

    setPersistedLinkString,
    persistedLinkString,

    setShowConfirmationSnackbar,
    showConfirmationSnackbar,

    showSuccessSnackbar,
    setShowSuccessSnackbar,

    pageSize,
    setPageSize,
    page,
    setPage,
    totalPage,
    totalData,

    handleDetectOnScrollEnd,
    tableContainerRef,

    etaBehaviorConfig,
    etaUserConfig,
    defaultEtaUserConfig,
    showEtaSettingList,
    setShowEtaSettingList,
    setIsSuperAdmin,
    isSuperAdmin,
    setListOfOutlets,
    listOfOutlets,

    isHasGetOutletWithConfig,
    getOutletWithMappedOutletEmails,

    hasRegisterSelectedOutlets,
    setHasRegisterSelectedOutlets,

    setDataToSaveConfigurationPage,
    dataToSaveConfigurationPage,

    setSelectedOutlet,
    selectedOutlet,

    callbackPWA,

    outletList,
    setOutletList,

    handleSearchOutlet,

    setInitOutletList,
    initOutletList,

    setIsEmailAutomationDialogOpen,
    isEmailAutomationDialogOpen
  }

  return (
    <>
      <PwaContextProvider>
        <AppContext.Provider value={AppContextValue}>
          <ThemeProvider theme={getAppTheme(mode)}>
            <CssBaseline />
            <ToastContainer
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnHover={false}
              pauseOnFocusLoss={false}
              position="bottom-left"
              them="light"
            />
            <Main
              openDialog={openDialog}
              isLoadingLogin={isLoadingLogin}
              isLoadingTenant={isLoadingTenant}
              showAppBar={showAppBar}
              handleOpenNavigationDrawer={handleOpenNavigationDrawer}
              isLogin={isLogin}
              handleOpenDialog={handleOpenDialog}
              shouldShowSettings={shouldShowSettings}
              isError={isError}
            />
            <NavigationDrawer
              anchorNavigationDrawerEl={anchorNavigationDrawerEl}
              setAnchorNavigationDrawerEl={setAnchorNavigationDrawerEl}
              isNavigationDrawerOpen={isNavigationDrawerOpen}
              handleCloseNavigationDrawer={handleCloseNavigationDrawer}
            />
            <InviteUserDialog
              outletList={outletList}
              isOpen={openDialog === DialogType.INVITEUSER}
              outletId={outletId}
              setIsDisplayApiSuccesMessage={setIsDisplayApiSuccesMessage}
              isDisplayApiSuccesMessage={isDisplayApiSuccesMessage}
              setIsApiLoading={setIsApiLoading}
              isApiLoading={isApiLoading}
            />
          </ThemeProvider>
        </AppContext.Provider>
      </PwaContextProvider>
    </>
  );
}

export default App;
